<template>
  <div class="budget-box">
    <!-- banner部分 -->
    <div class="budget-banner">
      <div class="budget-bannerbox">
        <div class="budget-leftbox">
          <div class="budget-contentbox">
            <div class="budget-title family-title">预算系统</div>
            <div class="budget-miaosu family-text">数字化赋能预算，助力企业精细化运营</div>
            <div class="budget-shen">
              <div class="budget-ansen family-picturetext" @click="Buguse">
                申请体验
              </div>
            </div>
          </div>
        </div>
        <div class="budget-rightbox">
          <div class="budget-imgbox">
            <img
              src="https://qncdn.uni-sheep.com/s/Group%20869.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 牧业预算角度 -->
    <div class="animal-box">
      <div class="animal-center">
        <div class="animal-top">
          <div class="animal-timu family-title">牧业预算角度</div>
          <div class="animal-henxian"></div>
        </div>
        <div class="animal-bottom">
          <div class="animal-background">
            <div class="animal-tio">
              <div class="animal-tcen">
                <div class="animal-yuan">1</div>
                <div class="animal-title family-title">畜只预算</div>
              </div>
            </div>
            <div class="animal-bto">
              <div class="animal-btocen">
                <div class="animal-contebox">
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">畜只群体结构变化趋势</div>
                  </div>
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">各类别畜只占比</div>
                  </div>
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">新生趋势等</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="animal-background">
            <div class="animal-tio">
              <div class="animal-tcen">
                <div class="animal-yuan">2</div>
                <div class="animal-title family-title">进销存</div>
              </div>
            </div>
            <div class="animal-bto">
              <div class="animal-btocen">
                <div class="animal-contebox">
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">人力成本预算</div>
                  </div>
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">储备人才</div>
                  </div>
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">销售库存</div>
                  </div>
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">人畜配置</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="animal-background">
            <div class="animal-tio">
              <div class="animal-tcen">
                <div class="animal-yuan">3</div>
                <div class="animal-title family-title">财务预算</div>
              </div>
            </div>
            <div class="animal-bto">
              <div class="animal-btocen">
                <div class="animal-contebox">
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">费用</div>
                  </div>
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">成本</div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <div class="animal-background">
            <div class="animal-tio">
              <div class="animal-tcen">
                <div class="animal-yuan">4</div>
                <div class="animal-title family-title">利润统计</div>
              </div>
            </div>
            <div class="animal-bto">
              <div class="animal-btocen">
                <div class="animal-contebox">
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">资产负债</div>
                  </div>
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">利润表</div>
                  </div>
                  <div class="animal-henbox">
                    <div class="animal-dui">
                      <img
                        src="https://qncdn.uni-sheep.com/s/%E5%AF%B9%E5%8B%BE%20%281%29%281%29.png"
                        alt=""
                      />
                    </div>
                    <div class="animal-ht family-text">现金流量表</div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 牧场现状 -->
    <div class="situation-box">
      <div class="situation-centerbox" v-if="situationdisplay">
        <div class="situation-top">
          <div class="situation-tit family-title">牧场现状</div>
          <div class="situation-hen"></div>
        </div>
        <div class="situation-bottom"></div>
      </div>
    </div>
    <!-- 预算目的 -->
    <div class="purpose-box">
      <div class="purpose-centerbox" v-if="purposedisplay">
        <div class="purpose-top">
          <div class="purpose-tit family-title">预算目的</div>
          <div class="purpose-hen"></div>
        </div>
        <div class="purpose-bottom">
          <div class="purpose-liftbox">
            <div class="pur-top">
              <div class="pur-tit">制定战略目标</div>
              <div class="pur-nei family-text">
                通过对企业未来3-5年的预算得到的直观数据，企业领导层可以更好对企业未来发展的规划及目标经行研判，从而制定及调整合理的目标。
              </div>
              <img
                src="https://www.canway.net/assets/vie/img/iconAni1_1.png"
                alt=""
                class="image1"
              />
              <img
                src="https://www.canway.net/assets/vie/img/iconAni1.png"
                alt=""
                class="image2"
              />
            </div>
            <div class="pur-top">
              <div class="pur-tit">KPI体系建设</div>
              <div class="pur-nei family-text">
                以预算数据为基础，建立的考核体系。减少偏差，更符合牧场实际生产管理
              </div>
              <img
                src="https://www.canway.net/assets/vie/img/iconAni1_1.png"
                alt=""
                class="image1"
              />
              <img
                src="https://www.canway.net/assets/vie/img/iconAni1.png"
                alt=""
                class="image2"
              />
            </div>
          </div>
          <div class="purpose-liftbox">
            <div class="pur-top">
              <div class="pur-tit">目标的制定原则</div>
              <div class="pur-nei family-text">
                预算数据围绕未来蓄群结构，物料投入，人员配置，产出测算等角度合理制定目标。
              </div>
              <img
                src="https://www.canway.net/assets/vie/img/iconAni1_1.png"
                alt=""
                class="image1"
              />
              <img
                src="https://www.canway.net/assets/vie/img/iconAni1.png"
                alt=""
                class="image2"
              />
            </div>
            <div class="pur-top">
              <div class="pur-tit">经营情况分析</div>
              <div class="pur-nei family-text">
                未来预算数据与实际经营情况经行比对，及时分析原因，准确调整企业运营，加快效率，减少成本。
              </div>
              <img
                src="https://www.canway.net/assets/vie/img/iconAni1_1.png"
                alt=""
                class="image1"
              />
              <img
                src="https://www.canway.net/assets/vie/img/iconAni1.png"
                alt=""
                class="image2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--预算系统介绍  -->
    <div class="presentation-box">
      <div class="per-centerbox" v-if="presedisplay">
        <div class="per-top">
          <div class="per-tit family-title">预算系统介绍</div>
          <div class="per-hen"></div>
        </div>
        <div class="per-bottom">
          <div class="per-leftbox">
            <div
              class="per-content"
              v-for="(item, index) in perlist"
              :key="index"
              :class="{ active: index == num }"
              @click="changes(index)"
              @mouseover="mouserOve(index)"
            >
              <div class="per-nuber">{{ item.number }}</div>
              <div class="per-tbox">
                <div class="per-tcon family-text">{{ item.title }}</div>
                <div class="per-tmiao family-text">{{ item.permiao }}</div>
              </div>
              <div class="per-img">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="per-imgs">
                <img :src="item.imgUrls" alt="" />
              </div>
            </div>
          </div>

          <div class="per-rightbox" v-show="num == 0">
            <div class="per-toright">
              <div class="per-title family-title">预算分析</div>
              <div class="per-trimg">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%20500%20%281%29%281%29.png"
                  alt=""
                />
              </div>
            </div>
            <div class="per-rihcont">
              <img src="https://qncdn.uni-sheep.com/s/1.png" alt="">
            </div>
          </div>

          <div class="per-rightbox" v-show="num == 1">
            <div class="per-toright">
              <div class="per-title family-title">分析报表展示（多维度、多角度）</div>
              <div class="per-trimg">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%20500%20%281%29%281%29.png"
                  alt=""
                />
              </div>
            </div>
            <div class="per-rihcont">
              <img src="https://qncdn.uni-sheep.com/s/2.png" alt="">
            </div>
          </div>
          <div class="per-rightbox" v-show="num == 2">
            <div class="per-toright">
              <div class="per-title family-title">结果展示</div>
              <div class="per-trimg">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%20500%20%281%29%281%29.png"
                  alt=""
                />
              </div>
            </div>
            <div class="per-rihcont">
              <img src="https://qncdn.uni-sheep.com/s/3.png" alt="">
            </div>
          </div>
          <div class="per-rightbox" v-show="num == 3">
            <div class="per-toright">
              <div class="per-title">预算分析4</div>
              <div class="per-trimg">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%20500%20%281%29%281%29.png"
                  alt=""
                />
              </div>
            </div>
            <div class="per-rihcont">
              我们是一个温度计我到家的嗲精的弟弟忘记第几答复啊好滴五大道八度我的
              味道怒我的怒我我的我反胃佛龛飞机父母热敷加入腐发不考试的科目我的吧
              符号借东风假过年吧地方让人脆弱如图看风景吧哥热额得到。
            </div>
          </div>
          <div class="per-rightbox" v-show="num == 4">
            <div class="per-toright">
              <div class="per-title">预算分析5</div>
              <div class="per-trimg">
                <img
                  src="https://qncdn.uni-sheep.com/s/Group%20500%20%281%29%281%29.png"
                  alt=""
                />
              </div>
            </div>
            <div class="per-rihcont">
              我们是一个温度计我到家的嗲精的弟弟忘记第几答复啊好滴五大道八度我的
              味道怒我的怒我我的我反胃佛龛飞机父母热敷加入腐发不考试的科目我的吧
              符号借东风假过年吧地方让人脆弱如图看风景吧哥热额得到。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公共脚步部分 -->
    <public-footer @Immediately="Immediately"></public-footer>
  </div>
</template>
<script>

import PublicFooter from "../../../components/publicfooter/index.vue";

export default {
  metaInfo: {
    title: '预算系统-自牧天成科技发展有限公司',
    // meta: [
    //   {
    //     name: 'description',
    //     content: 'This is my page description'
    //   }
    // ]
  },
  props: [],
  components: {
    PublicFooter,
  },
  data() {
    return {
      perlist: [
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Vector%20%285%29%281%29.png",
          imgUrls: "https://qncdn.uni-sheep.com/s/Group%20501%20%281%29%281%29.png",
          title: "分析报表展示",
          permiao: "各类畜只未来3-5年占比数据",
          number: "01",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Vector%20%285%29%281%29.png",
          imgUrls: "https://qncdn.uni-sheep.com/s/Group%20501%20%281%29%281%29.png",
          title: "预算分析表",
          permiao: "整体的牧场预算进行分析",
          number: "02",
        },
        {
          imgUrl: "https://qncdn.uni-sheep.com/s/Vector%20%285%29%281%29.png",
          imgUrls: "https://qncdn.uni-sheep.com/s/Group%20501%20%281%29%281%29.png",
          title: "结果展示",
          permiao: "最终结果的展示方式及数据",
          number: "03",
        },
      ],

      num: 0,
      // 预算角度动画
      situationdisplay: false,
      //预算目的动画
      purposedisplay: false,
      //预算系统介绍
      presedisplay: false,
    };
  },
  methods: {
    changes(key) {
      this.num = key;
    },
    mouserOve(key) {
      this.num = key;
    },
    // 立即咨询
    Immediately() {
      this.$router.push("/fillinformation");
    },
    // 申请体验
    Buguse(){
      this.$router.push("/fillinformation");
    },
    // 监听浏览器
    listenerFunction() {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    // 获取浏览器滚动条高度
    handleScroll(event) {
      // console.log("滚动条高度为：", event.target.scrollTop);
      this.scrollTop = event.target.scrollTop;
      const topObj = {
        200: "situationdisplay",
        800: "purposedisplay",
        1500: "presedisplay",
      };
      for (const top in topObj) {
        this.scrollTop >= top && (this[topObj[top]] = true);
      }
    },
  },
  created() {
    this.listenerFunction();
  },
  beforeDistroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },

};
</script>
<style scoped lang="scss">

// 公共动画
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
// 预算系统介绍内容动画
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

.budget-box {
  width: 100%;
  height: 100%;
  //banner部分
  .budget-banner {
    width: 100%;
    height: 38rem;
    background: #eef3fe;
    .budget-bannerbox {
      width: 72.5rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      .budget-leftbox {
        width: 24%;
        height: 100%;
        display: flex;
        align-items: center;
        .budget-contentbox {
          width: 30rem;
          height: 18rem;
          display: flex;
          flex-direction: column;
          .budget-title {
            font-size: 2rem;
            font-weight: 800;
            padding-left: 0.5rem;
          }
          .budget-miaosu {
            width: 100%;
            height: 4rem;
            line-height: 2rem;
            font-size: 1rem;
            color: #8d91a7;
            margin-top: 2rem;
          }
          .budget-shen {
            width: 100%;
            height: 3rem;
             .budget-ansen{
              width: 142px;
              height: 49px;
              background: #3371FF;
              text-align: center;
              line-height:49px ;
              color:white;
              border-radius: 5px;
              cursor: pointer;
            }
            .budget-ansen:hover{
              background: blue;
            }
          }
        }
      }
      .budget-rightbox {
        width: 71.7%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content:flex-end;
        .budget-imgbox {
          width: 45.75rem;
          height: 24rem;
          img{
            width: 100%;
            height: 100%;
          }
        
        }
      }
    }
  }
  //牧业预算角度
  .animal-box {
    width: 100%;
    height: 30rem;
    .animal-center {
      width: 66.875rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .animal-top {
        width: 13rem;
        height: 4rem;
        margin: 0 auto;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .animal-timu {
          font-size: 2rem;
          font-weight: 600;
        }
        .animal-henxian {
          width: 12rem;
          height: 0.25rem;
          background: #4668e3;
        }
      }
      .animal-bottom {
        width: 100%;
        height: 20rem;
        display: flex;
        justify-content: space-between;
        .animal-background {
          width: 23%;
          height: 100%;
          background: url("https://qncdn.uni-sheep.com/s/%E8%83%8C%E6%99%AF%281%29.png");
          background-repeat: no-repeat;
          background-size: cover;
          transition: box-shadow 0.3s ease;
          .animal-tio {
            width: 100%;
            height: 4.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .animal-tcen {
              width: 52%;
              height: 2.5rem;
              display: flex;
              align-items: center;
              .animal-yuan {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                text-align: center;
                line-height: 1.5rem;
                color: white;
                font-size: 1rem;
                font-weight: 800;
                background: #7e9bfe;
              }
              .animal-title {
                color: white;
                font-size: 1.3rem;
                font-weight: 800;
                margin-left: 0.5rem;
              }
            }
          }
          .animal-bto {
            width: 100%;
            height: 14.5rem;
            .animal-btocen {
              width: 75%;
              height: 100%;
              margin-left: 1.6rem;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              .animal-contebox {
                width: 100%;
                height: 85%;
                .animal-henbox {
                  width: 100%;
                  height: 2.5rem;
                  display: flex;
                  align-items: center;
                  .animal-dui {
                    width: 1rem;
                    height: 1rem;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .animal-ht {
                    font-size: 1rem;
                    margin-left: 0.5rem;
                    color: #8d91a7;
                  }
                }
              }
            }
          }
        }
        .animal-background:hover {
          transform: translateY(-0.3125rem);
          cursor: pointer;
        }
      }
    }
  }
  // 牧场现状
  .situation-box {
    width: 100%;
    height: 35rem;
    background: #f8f9fe;
    .situation-centerbox {
      width: 1070px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .situation-top {
        width: 9rem;
        height: 4rem;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-content: space-around;
        .situation-tit {
          font-size: 2rem;
          font-weight: 600;
        }
        .situation-hen {
          width: 8rem;
          height: 0.25rem;
          background: #4668e3;
        }
      }
      .situation-bottom {
        width: 100%;
        height: 22rem;
        background: url("https://qncdn.uni-sheep.com/s/Group%20371%281%29.png")
          center center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 3rem;
      }
    }
  }
  // 预算目的
  .purpose-box {
    width: 100%;
    height: 46rem;
    .purpose-centerbox {
      width: 66.875rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .purpose-top {
        width: 9rem;
        height: 4rem;
      
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 3.5rem;
        .purpose-tit {
          font-size: 2rem;
          font-weight: 600;
        }
        .purpose-hen {
          width: 8rem;
          height: 0.25rem;
          background: #4668e3;
        }
      }
      .purpose-bottom {
        width: 100%;
        height: 32rem;
      
        display: flex;
        justify-content: space-between;
        margin-top: 3.5rem;
        .purpose-liftbox {
          width: 49%;
          height: 100%;
        
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .pur-top {
            width: 100%;
            height: 49%;
            box-shadow: 8px 9px 10px 0px #f1f2f9;
            border-radius: 0.3125rem;
            position: relative;
            cursor: pointer;
            img {
              width: 3rem;
              height: 3rem;
            }
            .image1 {
              position: absolute;
              top: 2.25rem;
              right: 1.875rem;
            }
            .image2 {
              position: absolute;
              top: 2.375rem;
              right: 1.875rem;
            }
            .pur-tit {
              position: absolute;
              font-size: 1.2rem;
              font-weight: 600;
              top: 1.25rem;
              left: 1.25rem;
            }
            .pur-nei {
              position: absolute;
              width: 75%;
              height: 70%;
            
              font-size: 1rem;
              line-height: 1.8rem;
              color: #8d91a7;
              top: 3.4375rem;
              left: 1.25rem;
            }
          }
          .pur-top:hover {
            background: #4668e3;
          }
          .pur-top.pur-zhon.pur-tit:hover {
            color: white;
          }
          .pur-top:hover .image1 {
            /* 添加第一张图片的 hover 移动效果 */
            /* 这里只是一个示例，你可以根据需要自定义样式 */
            margin-top: auto;
            transform: translateY(-8%);
          }
          .pur-top:hover .image2 {
            /* 添加第二张图片的 hover 移动效果 */
            /* 这里只是一个示例，你可以根据需要自定义样式 */
            transform: translateX(-12%);
          }
          .pur-top:hover .pur-tit {
            color: white;
          }
          .pur-top:hover .pur-nei {
            color: white;
          }
        }
      }
    }
  }
  //预算系统介绍
  .presentation-box {
    width: 100%;
    height: 50rem;
    background: #f8f9fe;
    .per-centerbox {
      width: 80.875rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      animation: slide-up 1.5s ease-in-out;
      animation-fill-mode: forwards;
      .per-top {
        width: 13rem;
        height: 3rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 3.8rem;
        .per-tit {
          font-size:2rem;
          font-weight: 600;
        }
        .per-hen {
          width: 12rem;
          height: 0.25rem;
          background: #4668e3;
        }
      }
      .per-bottom {
        width: 100%;
        height: 33rem;
        margin-top: 5.5rem;
        display: flex;
        justify-content: space-around;
        .per-leftbox {
          width: 26%;
          height: 100%;
          .per-content {
            width: 100%;
            height: 20%;
            border-radius: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;
            .per-nuber {
              font-size: 2.5rem;
              font-weight: 800;
            }
            .per-tbox {
              width: 14rem;
              height: 50%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              .per-tcon {
                font-size: 1.125rem;
              }
              .per-tmiao {
                font-size: 1rem;
                color: #999999;
              }
            }
            .per-img {
              width: 0.875rem;
              height: 0.75rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .per-imgs {
              width: 1.75rem;
              height: 1.75rem;
              animation: iconTrans 0.2s ease-in-out;
              box-shadow: 0.125rem 0.125rem 0.5rem #145cfe45;
              display: none;
            }
            @keyframes iconTrans {
              0% {
                transform: scale(0.7);
              }
              100% {
                transform: scale(1);
              }
            }
          }
          .active {
            background: #0a41ff;
            box-shadow: 1px 2px 21px -3px #0a41ff;
            .per-nuber {
              color: white;
            }
            .per-tbox {
              color: white;
              .per-tmiao {
                color: white;
              }
            }
            .per-img {
              display: none;
            }
            .per-imgs {
              display: block;
            }
          }
        }
        .per-rightbox {
          width: 58%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          animation: fadeInRight 1s 0.2s ease both;
          .per-toright {
            width: 100%;
            height: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .per-title {
              font-size: 1.5rem;
              font-weight: 800;
            }
            .per-trimg {
              width: 2rem;
              height: 0.5rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .per-rihcont {
            width: 100%;
            height: 85%;
           img{
            width: 100%;
            height: 100%;
           }
            
          }
        }
      }
    }
  }
}
</style>
